// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.35s,
		transition:			0.2s,
		banner:				2.5s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		2.75em,
		element-margin:		2em,
		inner:				65em
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		600,
		letter-spacing:		0.025em,
		letter-spacing-alt:	0.25em
	);

// Palette.
	$palette: (
		bg:					#242943,
		bg-alt:				#2a2f4a,
		fg:					#ffffff,
		fg-bold:			#ffffff,
		fg-light:			rgba(244,244,255,0.2),
		border:				rgba(212,212,255,0.1),
		border-bg:			rgba(212,212,255,0.035),
		highlight:			#9bf1ff,
		accent1:			#6F735F,
		accent2:			#D9CAAD,
		accent3:			#40250D,
		accent4:			#F2F2F2,
		accent5:			#D98236,
		accent6:			#D9CAAD
	);